import React from "react";

export default function Section5() {
  return (
    <div className="section__5">
      <h4>Growhouse Team</h4>
      <div className="team-grid">
        <div className="team-member">
          <img src="/harald.jpeg" alt="Harald Goldkuhl" />
          <h3>Harald Goldkuhl</h3>
          <p>Content Producer</p>
        </div>
        <div className="team-member">
          <img src="/edvin.jpg" alt="Edvin Zetterström" />
          <h3>Edvin Zetterström</h3>
          <p>Content Producer</p>
        </div>
        <div className="team-member">
          <img src="/felix.jpeg" alt="Felix Stolpe" />
          <h3>Felix Stolpe</h3>
          <p>Developer</p>
        </div>
        <div className="team-member">
          <img src="/mario.jpeg" alt="Mario Gajdeczka" />
          <h3>Mario Gajdeczka</h3>
          <p>Content Strategist</p>
        </div>
        <div className="team-member">
          <img src="/lucas.jpeg" alt="Lucas Stål Madison" />
          <h3>Lucas Stål Madison</h3>
          <p>Cofounder & Social Media Specialist</p>
        </div>
        <div className="team-member">
          <img src="/reagan.jpeg" alt="Reagan Wallis" />
          <h3>Reagan Wallis</h3>
          <p>Social Media Manager & Producer</p>
        </div>
        <div className="team-member">
          <img src="/erica.jpeg" alt="Erica Hjertqvist" />
          <h3>Erica Hjertqvist</h3>
          <p>Social Media Manager & Producer</p>
        </div>
        <div className="team-member">
          <img src="/alex.jpeg" alt="Alexander Adolfsson" />
          <h3>Alexander Adolfsson</h3>
          <p>Cofounder, Social Media Specialist & Data Analyst</p>
        </div>
      </div>
    </div>
  );
}
