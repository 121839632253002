import React, { useEffect } from "react";

export default function Section7() {
  useEffect(() => {
    // Dynamically load the external script
    const script = document.createElement("script");
    script.src = "https://testimonial.to/js/iframeResizer.min.js";
    script.async = true;
    document.body.appendChild(script);

    // Once the script is loaded, initialize the iframe resizer
    script.onload = () => {
      // Assuming the iFrameResize function is available globally after the script loads
      window.iFrameResize(
        { log: false, checkOrigin: false },
        "#testimonialto-wall-of-love-for-testimonial-light"
      );
    };

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="section__7">
      <h2>Wall of love</h2>
      <iframe
        className="testimonial-wall"
        id="testimonialto-wall-of-love-for-testimonial-light"
        src="https://embed-v2.testimonial.to/w/growhouse?theme=light&card=base&loadMore=on&initialCount=20&randomize=on&hideSource&tag=all"
        frameBorder="0"
        scrolling="no"
      ></iframe>
    </div>
  );
}
