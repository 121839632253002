import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../style/Content.css";
import api from "../config/Api";

export default function Sextonnio() {
  const [videos, setVideos] = useState([]);
  const location = useLocation();
  const pathName = location.pathname;
  const path = pathName.split("/")[2];

  console.log(path);
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await api.get("/content/live", {
          params: {
            path,
          },
        });
        if (response.status !== 200) {
          console.log("Response not equal to 200");
          return;
        }
        console.log("Res:", response.data);
        setVideos(response.data);
      } catch (error) {
        console.log("Error in fetch images", error.message);
      }
    };
    fetchContent();
  }, []);

  return (
    <div className="content">
      <div className="content__hero">
        <h1>
          16:9
          <br />
        </h1>
      </div>
      <div className="content__1">
        <div className="line"></div>
        <div className="sexonio__grid">
          {" "}
          {/* Updated class name */}
          {videos &&
            videos.map((video, key) => {
              const url = `https://res.cloudinary.com/dlafafq1q/video/upload/f_auto:video,q_auto/v1/${video.public_id}`;
              return (
                <div key={key} className="sexonio__box">
                  {" "}
                  {/* Updated class name */}
                  <video autoPlay loop muted>
                    <source src={url} type="video/mp4" />
                  </video>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
