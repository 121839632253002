import axios from "axios";

const baseUrl =
  window.location.origin === "http://localhost:3000"
    ? "http://localhost:3003"
    : "https://growhouse.co";

const api = axios.create({
  baseURL: `${baseUrl}/api`,
  withCredentials: true,
});

export default api;
