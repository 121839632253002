import * as React from "react";
import { Link } from "react-router-dom";

const Popup = ({
  onClose,
  handleInputChange,
  submitRequest,
  requestError,
  isFormSubmitted,
}) => (
  <>
    <div className="overlay" onClick={onClose}></div>
    <main className="popup-container">
      <div className="popup-content">
        <div className="popup-column">
          {isFormSubmitted ? <ThankYouSection /> : <WelcomeSection />}
        </div>
        <div className="popup-column">
          <RequestForm
            handleInputChange={handleInputChange}
            submitRequest={submitRequest}
            requestError={requestError}
          />
        </div>
      </div>
    </main>
  </>
);

const ThankYouSection = () => (
  <section className="popup-welcome">
    <h2 className="popup-title">Thanks!</h2>
    <p className="popup-message">
      Felix will be in touch shortly!
      <br />
      <br />
      Meanwhile, please go ahead and try our free social media tools.
    </p>
    <Link to="https://grow.growhouse.co">
      <button className="popup-submit free-tool-btn">Explore and grow.</button>
    </Link>
  </section>
);

const KeyFeature = ({ title, icon, description }) => (
  <div className="feature-card">
    <h3 className="feature-title">{title}</h3>
    <div className="feature-content">
      <span className="feature-icon">{icon}</span>
      <p className="feature-description">{description}</p>
    </div>
  </div>
);

const InputField = ({ placeholder, onChange, name }) => (
  <input
    className="popup-input"
    type="text"
    placeholder={placeholder}
    aria-label={placeholder}
    onChange={onChange} // Added onChange prop
    name={name} // Added name prop
  />
);

const WelcomeSection = () => (
  <section className="popup-welcome">
    <h2 className="popup-title">Hi there!</h2>
    <p className="popup-message">
      Please, feel free to enter as much information about your business as you
      wish.
      <br />
      <br />
      What e-commerce platform and which social channels do you use?
      <br />
      <br />
      Felix will recive an email with all the information you provide, and reply
      back as soon as possible.
    </p>
  </section>
);

const RequestForm = ({ handleInputChange, submitRequest, requestError }) => (
  <form className="popup-form" onSubmit={submitRequest}>
    <InputField placeholder="Name" onChange={handleInputChange} name="name" />
    <InputField placeholder="Email" onChange={handleInputChange} name="email" />
    <textarea
      onChange={handleInputChange}
      className="popup-textarea"
      placeholder="Message"
      aria-label="Message"
      name="message"
    ></textarea>
    {requestError && <p className="request-error-message">{requestError}</p>}
    <button className="popup-submit" type="submit">
      Send Request
    </button>
  </form>
);

const SocialMediaROICard = ({ imageSrc, altText }) => (
  <section className="roi-card">
    <img src={imageSrc} alt={altText} className="roi-image" />
  </section>
);

function SellSeed() {
  const [isPopupVisible, setPopupVisible] = React.useState(false);
  const [requestError, setRequestError] = React.useState(null);
  const [isFormSubmitted, setFormSubmitted] = React.useState(false); // New state

  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    message: "",
  });
  const handleBookingClick = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const submitRequest = async (e) => {
    e.preventDefault();
    if (
      formData.name === "" ||
      formData.email === "" ||
      formData.message === ""
    ) {
      setRequestError("Please fill in all fields");
      return;
    }
    try {
      const response = await fetch("https://growhouse.co/api/seed/email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      console.log("Form submitted:", result);
      setFormSubmitted(true); // Set form submission status
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const keyFeatures = [
    { title: "COOKIES", icon: "🤖", description: "No storage in browsers" },
    { title: "GDPR", icon: "🇪🇺", description: "No peronal data" },
    { title: "ORGANIC", icon: "🌱", description: "Complement Ads data" },
  ];

  const roiCards = [
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/51d36b40795596aeeef9d4904ed9250b63739650d583571c4d86cabc5b3a03c1?apiKey=680ae4d916a6424eac83b1fd8bdc2063&",
      altText: "Social Media ROI Chart 1",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/6b3f5777098fbce593729df80e602380e28f09f6bfb2806ece5b052a9bd131e4?apiKey=680ae4d916a6424eac83b1fd8bdc2063&",
      altText: "Social Media ROI Chart 2",
    },
  ];

  return (
    <>
      <style jsx>{`
        .main-header {
          position: sticky;
          top: 0;
          display: flex;
          padding: 0.5% 4% 0.3% 4%;
          justify-content: space-between;
          align-items: center;

          background-color: #fff;
          z-index: 100;
        }

        .hero-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .free-tool-btn {
          margin-top: 4%;
        }
        .main-nav {
          display: flex;
          gap: 20px;
        }
        .main-nav a {
          text-decoration: none;
          color: #000;
          font: 16px Inter, sans-serif;
        }
        .login-button {
          justify-content: center;
          border-radius: 52px;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
          border: 2px solid rgba(13, 169, 66, 1);
          background-color: #fff;
          color: #0cb545;
          padding: 13px 48px;
          font: 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .login-button {
            padding: 13px 20px;
          }
          .main-nav {
            display: none;
          }
        }
        .visually-hidden {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border: 0;
        }

        .page-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .main-container {
          background: linear-gradient(180deg, #fff 4.81%, #fedab8 88.23%);
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        @media (max-width: 991px) {
          .main-container {
            padding: 0 20px;
          }
        }
        .logo {
          color: black;
          flex-grow: 1;
          flex-basis: auto;
          text-align: left;
          margin: 0 auto;
          font: 32px Inter, sans-serif;
        }
        .logo-text {
          font-size: 24px;
          margin-left: 12px;
          color: #515151;
        }
        .login-button {
          justify-content: center;
          border-radius: 52px;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
          border: 2px solid rgba(13, 169, 66, 1);
          background-color: #fff;
          color: #0cb545;
          padding: 13px 48px;
          font: 16px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .login-button {
            padding: 13px 20px;
          }
        }
        .trusted-by {
          color: #000;
          text-align: center;
          letter-spacing: 3.96px;
          margin-top: 206px;
          font: 400 18px Inter, sans-serif;
        }
        .trusted-by a {
          padding-left: 5px;
          padding-right: 5px;
        }
        @media (max-width: 991px) {
          .trusted-by {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .hero-title {
          color: black;
          text-align: center;
          margin-top: 37px;
          font: 700 88px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .hero-title {
            max-width: 100%;
            font-size: 40px;
          }
        }
        .hero-subtitle {
          color: #000;
          text-align: center;
          margin-top: 30px;
          font: 400 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .hero-subtitle {
            max-width: 100%;
          }
        }
        .cta-container {
          display: flex;
          margin-top: 40px;
          margin-bottom: 5%;
          width: 682px;
          max-width: 100%;
          gap: 20px;
          font-size: 20px;
          font-weight: 600;
          text-align: center;
          justify-content: center;
        }
        @media (max-width: 991px) {
          .cta-container {
            flex-wrap: wrap;
          }
        }
        .cta-primary {
          font-family: Inter, sans-serif;
          justify-content: center;
          border-radius: 52px;
          background: linear-gradient(
            90deg,
            rgba(19, 215, 86, 0.95) 0.12%,
            rgba(12, 158, 61, 0.9) 99.91%
          );
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
          color: #fff;
          padding: 21px 34px;
        }
        @media (max-width: 991px) {
          .cta-primary {
            padding: 21px 20px;
          }
        }
        .cta-secondary {
          font-family: Inter, sans-serif;
          justify-content: center;
          align-items: center;
          border-radius: 52px;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
          border: 2px solid rgba(13, 169, 66, 1);
          background-color: #fff;
          color: #0cb545;
          padding: 21px 60px;
        }
        @media (max-width: 991px) {
          .cta-secondary {
            padding: 21px 20px;
          }
        }
        .hero-image {
          aspect-ratio: 1.82;
          object-fit: cover;
          width: 100%;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2),
            0 0 2px 0 rgba(0, 0, 0, 0.08), 12px 12px 6px 0 rgba(0, 0, 0, 0.22);
          border: 8px solid rgba(255, 255, 255, 1);
          margin-top: 151px;
          max-width: 1137px;
        }
        @media (max-width: 991px) {
          .hero-image {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .section-label {
          justify-content: center;
          border-radius: 52px;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
          border: 1px solid rgba(0, 0, 0, 1);
          background-color: #fff;
          margin-top: 60px;
          color: #0e0e0e;
          text-align: center;
          padding: 13px 58px;
          font: 600 20px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .section-label {
            margin-top: 20px;
            padding: 13px 20px;
          }
        }
        .section-title {
          color: #000;
          text-align: center;
          margin-top: 124px;
          font: 700 72px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .section-title {
            max-width: 100%;
            margin-top: 40px;
            font-size: 40px;
          }
        }
        .section-description {
          color: #000;
          text-align: center;
          margin-top: 27px;
          font: 400 24px Inter, sans-serif;
        }
        .section-description p {
          line-height: 150%;
        }
        @media (max-width: 991px) {
          .section-description {
            max-width: 100%;
          }
        }
        .features-container {
          margin-top: 70px;
          width: 100%;
          max-width: 1287px;
        }
        @media (max-width: 991px) {
          .features-container {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .features-grid {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .features-grid {
            flex-direction: column;
            align-items: stretch;
            gap: 0;
          }
        }
        .feature-card {
          align-content: center;
          flex-wrap: wrap;
          border-radius: 37px;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
          background-color: #fff;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          color: #000;
          margin: 0 30px;
          padding: 27px 25px;
        }
        @media (max-width: 991px) {
          .feature-card {
            margin-top: 40px;
            padding: 27px 20px;
          }
        }
        .feature-title {
          text-align: center;
          font: 700 62px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .feature-title {
            font-size: 40px;
          }
        }
        .feature-content {
          display: flex;
          margin-top: 23px;
          gap: 15px;
          font-weight: 400;
          align-items: center;
        }
        .feature-icon {
          text-align: center;
          font: 32px Inter, sans-serif;
        }
        .feature-description {
          font: 22px Inter, sans-serif;
        }
        .roi-section-label {
          justify-content: center;
          align-items: center;
          border-radius: 52px;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
          border: 1px solid rgba(0, 0, 0, 1);
          background-color: #fff;
          margin-top: 56px;
          width: 242px;
          max-width: 100%;
          color: #0e0e0e;
          text-align: center;
          padding: 13px 60px;
          font: 600 20px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .roi-section-label {
            margin-top: 40px;
            padding: 13px 20px;
          }
        }
        .roi-title {
          color: #000;
          text-align: center;
          margin-top: 164px;
          font: 700 72px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .roi-title {
            max-width: 100%;
            margin-top: 40px;
            font-size: 40px;
          }
        }
        .roi-description {
          color: #000;
          text-align: center;
          margin-top: 47px;
          font: 400 24px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .roi-description {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .roi-main-card {
          border-radius: 37px;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
          background-color: #fff;
          display: flex;
          margin-top: 50px;
          width: 100%;
          max-width: 60%;
          flex-direction: column;
          justify-content: center;
          padding: 27px 24px;
        }
        @media (max-width: 991px) {
          .roi-main-card {
            max-width: 100%;
            margin-top: 40px;
            padding: 27px 20px;
          }
        }
        .roi-main-image {
          aspect-ratio: 2.63;
          object-fit: cover;
          width: 100%;
        }
        @media (max-width: 991px) {
          .roi-main-image {
            max-width: 100%;
          }
        }
        .roi-cards-container {
          margin-top: 40px;
          width: 100%;
          max-width: 63%;
        }
        @media (max-width: 991px) {
          .roi-cards-container {
            max-width: 100%;
          }
        }
        .roi-cards-grid {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .roi-cards-grid {
            flex-direction: column;
            align-items: stretch;
            gap: 0;
          }
        }
        .roi-card {
          border-radius: 37px;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
          background-color: #fff;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          padding: 21px 30px;
        }
        @media (max-width: 991px) {
          .roi-card {
            max-width: 100%;
            margin-top: 40px;
            padding: 21px 20px;
          }
        }
        .roi-image {
          aspect-ratio: 2.56;
          object-fit: cover;
          width: 100%;
        }
        @media (max-width: 991px) {
          .roi-image {
            max-width: 100%;
          }
        }
        .start {
          background-color: #fff;
          align-self: stretch;
          display: flex;
          margin-top: 155px;
          flex-direction: column;
          align-items: center;
          color: #000;
          padding: 53px 60px 29px;
        }
        @media (max-width: 991px) {
          .start {
            max-width: 100%;
            margin-top: 40px;
            padding: 53px 20px 29px;
          }
        }
        .start-content {
          display: flex;
          width: 100%;
          max-width: 1175px;
          gap: 20px;
          justify-content: space-between;
        }
        @media (max-width: 991px) {
          .start-content {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .start-text {
          display: flex;
          flex-direction: column;
          margin: auto 0;
          align-items: flex-start;
        }
        @media (max-width: 991px) {
          .start-text {
            max-width: 100%;
          }
        }
        .start-title {
          text-align: center;
          font: 700 72px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .start-title {
            max-width: 100%;
            font-size: 40px;
          }
        }
        .start-subtitle {
          margin-top: 12px;
          font: 500 28px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .start-subtitle {
            max-width: 100%;
          }
        }
        .start-cta {
          justify-content: center;
          border-radius: 52px;
          background: linear-gradient(
            90deg,
            rgba(19, 215, 86, 0.95) 0.12%,
            rgba(12, 158, 61, 0.9) 99.91%
          );
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
          align-self: start;
          margin-top: 35px;
          color: #fff;
          text-align: center;
          padding: 15px 22px;
          font: 600 17px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .start-cta {
            padding: 15px 20px;
          }
        }
        .start-profile {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-weight: 700;
          line-height: 150%;
        }
        .profile-image {
          aspect-ratio: 1.04;
          object-fit: cover;
          width: 189px;
        }
        .profile-name {
          letter-spacing: -0.13px;
          margin-top: 22px;
          font: 12px Roboto Mono, sans-serif;
        }
        .profile-title {
          text-align: center;
          letter-spacing: -0.09px;
          margin-top: 5px;
          font: 8px Roboto Mono, sans-serif;
        }
        .overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          z-index: 99;
        }
        .popup-container {
          position: fixed;
          width: 60%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 100;
          background: #ffffff;
          border: 10px solid black;
          border-radius: 24px;
          padding: 2% 2%;
        }
        .popup-content {
          display: flex;

          gap: 20px;
        }
        .popup-column {
          display: flex;
          flex-direction: column;
          width: 50%;
        }
        .popup-welcome {
          display: flex;
          flex-direction: column;
          color: #000;
        }
        .popup-title {
          font: 700 80px Inter, sans-serif;
        }
        .popup-message {
          margin-top: 4%;
          font: 400 24px Inter, sans-serif;
        }
        .popup-form {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          font-size: 20px;
          color: #0cb545;
          font-weight: 600;
          text-align: center;
        }
        .popup-textarea {
          border-radius: 29px;
          background-color: #fffcda3b;
          height: 50px;
          margin-bottom: 20px;
          padding-top: 4%;
          padding-left: 4%;
          border: 2px solid #0cb545;
        }
        .popup-input {
          border-radius: 29px;
          background-color: #fffcda3b;
          height: 50px;
          margin-bottom: 20px;
          padding-left: 4%;
          border: 2px solid #0cb545;
        }
        .popup-textarea {
          height: 211px;
        }
        .popup-submit {
          font-family: Inter, sans-serif;
          border-radius: 52px;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
          border: 2px solid #0da942;
          background-color: #fff;
          align-self: center;
          width: 100%;
          max-width: 100%;
          padding: 12px 60px;
          cursor: pointer;
        }
        .popup-close {
          position: absolute;
          top: 10px;
          right: 10px;
          background: none;
          border: none;
          font-size: 20px;
          cursor: pointer;
        }
        .request-error-message {
          color: #f46936;
          font-size: 14px;
          margin: 2%;
          text-align: left;
        }
        @media (max-width: 991px) {
          .popup-container {
            padding: 0 20px;
          }
          .popup-content {
            flex-direction: column;
            align-items: stretch;
            gap: 0;
          }
          .popup-column {
            width: 100%;
          }
          .popup-welcome {
            margin-top: 40px;
          }
          .popup-title {
            font-size: 40px;
          }
          .popup-message {
            margin-top: 40px;
          }
          .popup-form {
            margin-top: 40px;
          }
          .popup-submit {
            padding: 20px;
          }
          .request-error-message {
            margin-top: 10px;
          }
        }
      `}</style>
      <header className="main-header">
        <div className="logo">
          <span className="logo-icon">🌱</span>
          <span className="logo-text">Seed</span>
        </div>
        <button className="login-button">Login</button>
      </header>

      <main className="main-container">
        <div className="hero-container">
          <p className="trusted-by">
            Trusted by ✪
            <a
              href="https://www.poc.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              POC
            </a>
            ✪
            <a
              href="https://www.alpingaraget.se"
              target="_blank"
              rel="noopener noreferrer"
            >
              Alpingaraget
            </a>
            ✪
            <a
              href="https://www.bered.se"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bered
            </a>
            ✪
            <a
              href="https://bkm.health"
              target="_blank"
              rel="noopener noreferrer"
            >
              BKM
            </a>
            ✪
            <a
              href="https://www.stellarequipment.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stellar Equipment
            </a>
          </p>
          <h2 className="hero-title">
            The #1 <span style={{ color: "rgba(12,181,69,1)" }}>pixel</span> for
            social <br /> media driven e-commerces
          </h2>
          <p className="hero-subtitle">
            Track all social media traffic to your e-commerce and understand
            your audience.
          </p>
          <div className="cta-container">
            <button className="cta-primary" onClick={handleBookingClick}>
              Book Meeting
            </button>
            <Link to="https://grow.growhouse.co">
              <button className="cta-secondary">Try Free Tools</button>
            </Link>
          </div>
        </div>
        <div className="page-container">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd96d8d1ce56df76bfb695f764f5b3cc2d5aeec10fd6b1c55ad2de4612976683?apiKey=680ae4d916a6424eac83b1fd8bdc2063&"
            alt="Seed platform interface"
            className="hero-image"
          />
          <h2 className="section-title">Why is Seed better?</h2>
          <p className="section-description">
            Enhance customer experience by removing the consent pop-up
            <br />
            AND understand the value of your social media.
          </p>
          <div className="section-label">Key Features</div>

          <section className="features-container">
            <div className="features-grid">
              {keyFeatures.map((feature, index) => (
                <KeyFeature key={index} {...feature} />
              ))}
            </div>
          </section>
          <h2 className="roi-title">Social Media ROI</h2>
          <p className="roi-description">
            Build better relations with your customers and turn them into raving
            fans.
          </p>
          <div className="roi-section-label">Deep funnel</div>

          <div className="roi-main-card">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/030318c34af149ba3e871769430a33ca9a7a939703bfaa3d44a317692d17bec2?apiKey=680ae4d916a6424eac83b1fd8bdc2063&"
              alt="Social Media ROI Overview"
              className="roi-main-image"
            />
          </div>
          <section className="roi-cards-container">
            <div className="roi-cards-grid">
              {roiCards.map((card, index) => (
                <SocialMediaROICard key={index} {...card} />
              ))}
            </div>
          </section>
          <start className="start">
            <div className="start-content">
              <div className="start-text">
                <h2 className="start-title">Welcome!</h2>
                <p className="start-subtitle">
                  Book a meeting with Felix and see if Seed is for you.
                </p>
                <button className="start-cta" onClick={handleBookingClick}>
                  Book Meeting
                </button>
              </div>
              <div className="start-profile">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a032077a24bcef8cea47d260d88ac2e2ce7f7c1f6c41cd266732b02dff993c1?apiKey=680ae4d916a6424eac83b1fd8bdc2063&"
                  alt="Felix Civalero Stolpe"
                  className="profile-image"
                />
                <p className="profile-name">Felix Civalero Stolpe</p>
                <p className="profile-title">🌱 Product specialist</p>
              </div>
            </div>
          </start>
        </div>
        {isPopupVisible && (
          <Popup
            onClose={handleClosePopup}
            handleInputChange={handleInputChange}
            submitRequest={submitRequest}
            requestError={requestError}
            isFormSubmitted={isFormSubmitted}
          />
        )}
      </main>
    </>
  );
}

export default SellSeed;
