import React, { createContext, useContext } from "react";

// Creating the context
const CookieContext = createContext();

// Hook to use the context easily
export const useCookie = () => useContext(CookieContext);

// Provider component
export const CookieProvider = ({ children }) => {
  const allCookies = parseCookies(document.cookie);

  const relevantCookies = {
    jwt: allCookies.jaywetetkn,
    profile_picture: allCookies.profile_picture,
    name: allCookies.name,
    email: allCookies.email,
  };
  function parseCookies(cookieStr) {
    return cookieStr.split("; ").reduce((acc, pair) => {
      const [key, value] = pair.split("=");
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});
  }

  return (
    <CookieContext.Provider value={relevantCookies}>
      {children}
    </CookieContext.Provider>
  );
};
