import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./routes/router.js";
import { CookieProvider } from "./config/CookieContext.js";
import ErrorBoundary from "./config/ErrorBoundary.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <CookieProvider>
        <Router />
      </CookieProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
