import React from "react";

export default function Section1() {
  return (
    <div className="section__1">
      <div className="line"></div>
      <div className="totalslide">
        <div className="slider">
          <div className="slide-track">
            <div className="slide">
              <img className="slideimg" alt="agria" src="./agria.png" />
            </div>
            <div className="slide">
              <img className="slideimg" alt="mer" src="./mer.svg" />
            </div>
            <div className="slide">
              <img className="slideimg" alt="chimi" src="./chimi.png" />
            </div>
            <div className="slide">
              <img
                className="slideimg"
                alt="peak performance"
                src="./peak.png"
              />
            </div>
            <div className="slide">
              <img className="slideimg" alt="volvo" src="./volvo.png" />
            </div>
            <div className="slide">
              <img
                className="slideimg"
                alt="transparent"
                src="./transparent.png"
              />
            </div>
            <div className="slide">
              <img className="slideimg" alt="nissan" src="./nissan.png" />
            </div>
            <div className="slide">
              <img className="slideimg" alt="ssf" src="./ssf.png" />
            </div>
            <div className="slide">
              <img className="slideimg" alt="satake" src="./satake.png" />
            </div>
            <div className="slide">
              <img className="slideimg" alt="agria" src="./agria.png" />
            </div>
            <div className="slide">
              <img className="slideimg" alt="mer" src="./mer.svg" />
            </div>
            <div className="slide">
              <img className="slideimg" alt="chimi" src="./chimi.png" />
            </div>
            <div className="slide">
              <img
                className="slideimg"
                alt="peak performance"
                src="./peak.png"
              />
            </div>
            <div className="slide">
              <img className="slideimg" alt="volvo" src="./volvo.png" />
            </div>
            <div className="slide">
              <img
                className="slideimg"
                alt="transparent"
                src="./transparent.png"
              />
            </div>
            <div className="slide">
              <img className="slideimg" alt="nissan" src="./nissan.png" />
            </div>
            <div className="slide">
              <img className="slideimg" alt="ssf" src="./ssf.png" />
            </div>
            <div className="slide">
              <img className="slideimg" alt="satake" src="./satake.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
