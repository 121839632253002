import React from "react";
import { Link } from "react-router-dom";
import "../style/Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer__first">
        <div className="footer__logo">
          <img alt="growhouse logo" src="/gh-logo.png" />
        </div>
        <div className="footer__links">
          <h5>Contact</h5>
          <Link>Lucas@growhouse.co</Link>
          <Link>Alexander@growhouse.co</Link>
        </div>
      </div>
      <div className="footer__second">
        <div>Copyright © 2023</div>
        <div className="socials">
          <a href="https://linkedin.com/growhouse.co">LinkedIn</a>
          <a href="https://instagram.com/growhouse.co">Instagram</a>
          <Link to={"https://simpleanalytics.com/growhouse.co"}>
            📈 Pageviews
          </Link>
          <Link to={"https://testimonials.to/growhouse"}>
            🌟 Rate our service
          </Link>{" "}
        </div>
      </div>
    </div>
  );
}
