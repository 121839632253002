import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Section2() {
  return (
    <div className="section__2">
      <div>
        <h4>
          Growhouse is a team of{" "}
          <span className="underline thin">content producers</span>,{" "}
          <span className="underline thin">channel specialists</span>,{" "}
          <span className="underline thin">software developers</span>, and{" "}
          <span className="underline thin">data analysts</span> that helps
          e-commerce brands increase results on social media, email, and web.
          {/* Take your marketing <br /> to new heights with Growhouse organic
          <br />
          social media and <br />
          content marketing.
        </h4>
        {/* <div className="competence">
          <h2>1. Attention</h2>
          <h3>Capture and retain your audience.</h3>
          <h2>2. Audience</h2>
          <h3>Build and engage a strong community.</h3>

          <h2>3. Sales</h2>
          <h3>Drive purchases and brand loyality.</h3>
        </div> */}
          <Link to={"/content/reels"}>
            <button className="content-btn">Content library</button>
          </Link>
        </h4>
      </div>
      <div>
        {/* Add your images here, each with a 'stepped-image' class */}
        <Link to={"/content/tiktok"}>
          <video autoPlay loop muted playsInline>
            <source src="/gh_web_header_video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Link>
        {/* <img
          src="./proof.png"
          alt="Description"
          className="proof"
          style={{ zIndex: 1 }}
        /> */}
        {/* Repeat for each image, increasing the zIndex each time */}
      </div>
    </div>
  );
}
