import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "../style/Main.css";
import Landing from "../pages/Landing.js";
import NavBar from "../modules/NavBar.js";
import Footer from "../modules/Footer.js";
import Reels from "../pages/Reels.js";
import Tiktok from "../pages/Tiktok.js";
import Shorts from "../pages/Shorts.js";
import Sextonnio from "../pages/16:9.js";
import Privacy from "../pages/Privacy.js";
import Terms from "../pages/Terms.js";
import SellSeed from "../pages/SellSeed.js";

function ConditionalNavBar() {
  const location = useLocation();
  if (location.pathname === "/sell-seed") {
    return null;
  }
  return <NavBar />;
}

function Routing() {
  return (
    <Router>
      <ConditionalNavBar />
      <Routes>
        <Route path="/content/reels" element={<Reels />} />
        <Route path="/content/tiktok" element={<Tiktok />} />
        <Route path="/content/shorts" element={<Shorts />} />
        <Route path="/content/16:9" element={<Sextonnio />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/sell-seed" element={<SellSeed />} />
        <Route path="*" element={<Landing />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default Routing;
