import React from "react";

export default function Section6() {
  return (
    <div className="section__6">
      <div className="grow__container">
        <h1 className="grow__animation">Grow </h1>
        <div className="box-container">
          <div className="box">
            <div className="face front">
              <h1>AUDIENCE</h1>
            </div>
            <div className="face back">
              {" "}
              <h1>ORGANIC</h1>
            </div>
            <div className="face left">STATS</div>
            <div className="face right">INSIGHT</div>
            <div className="face top">
              {" "}
              <h1>SALES</h1>
            </div>
            <div className="face bottom">
              {" "}
              <h1>INSIGHTS</h1>
            </div>
          </div>
        </div>
      </div>
      <h4>Try our free tool and compare your stats to competitors</h4>
      <h2></h2>
      <div className="soil__button">
        <button
          onClick={(e) => {
            e.preventDefault();
            window.location = "https://grow.growhouse.co";
          }}
        >
          ANALYZE NOW
        </button>
      </div>
    </div>
  );
}
