import React, { useState, useEffect } from "react";

export default function Section3() {
  const [currentMonthName, setCurrentMonthName] = useState("");

  useEffect(() => {
    getCurrentMonthName();
  }, []);

  const getCurrentMonthName = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const currentMonth = new Date().getMonth();
    setCurrentMonthName(monthNames[currentMonth]);
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="section__3">
      <h1>2024 CLIENT RESULTS</h1>
      <div className="section__3-grid">
        <h3>+88x</h3>
        <h3>+31x</h3>
        <h3>+3.8x</h3>
        <p className="numbers">Monthly Reach</p>
        <p className="numbers">Monthly Engagement</p>
        <p className="numbers">Monthly Followers</p>
      </div>
    </div>
  );
}
