import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import trackEvent from "../../config/SimpleTracker";

export default function Hero() {
  const navigate = useNavigate();
  useEffect(() => {
    // Dynamically load the external script
    const script = document.createElement("script");
    script.src = "https://testimonial.to/js/widget-embed.js";
    script.async = true;
    document.body.appendChild(script);

    // Once the script is loaded, initialize the iframe resizer
    script.onload = () => {
      // Assuming the iFrameResize function is available globally after the script loads
      window.iFrameResize(
        { log: false, checkOrigin: false },
        "#testimonialto-wall-of-love-for-testimonial-light"
      );
    };

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  // Function to scroll to the bottom of the page
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth", // Optional: This makes the scroll smooth
    });
  };

  const handleClick = (clickName) => {
    trackEvent(clickName);
  };

  return (
    <div className="hero">
      <iframe
        className="testimonial-to-embed"
        id="testimonial-to-embed-light"
        src="https://embed-v2.testimonial.to/badge/growhouse?fontColor=ffffff&fontFamily=Inter&reviewTerm=review&fontSize=16&reviewTermPlural=reviews&alignment=left"
        frameBorder="0"
        scrolling="no"
      ></iframe>
      <h1>
        Better Content,
        <br />
        Faster Growth
      </h1>
      <ul className="usp-bullets">
        <li>
          🌱 Reach more people at <span className="underline">lower cost</span>
        </li>
        <li>
          🌿 Turn viewers into followers -> fans ->{" "}
          <span className="underline">buyers</span>
        </li>
        <li>
          🌴 Nurture loyal{" "}
          <span className="underline">recurring customers</span>
        </li>
      </ul>

      <div className="cta__div">
        {/* Attach the scrollToBottom function to the onClick event */}
        <button
          onClick={(e) => {
            e.preventDefault();
            handleClick("click_contact");
            scrollToBottom();
          }}
        >
          Contact
        </button>
        {/* <button
          onClick={(e) => {
            e.preventDefault();
            handleClick("click_free_analysis");
            window.location = "https://grow.growhouse.co";
          }}
        >
          Get Free analysis
        </button> */}
      </div>
    </div>
  );
}
