// ErrorBoundary.js
import React from "react";
import "../style/Error.css";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render can show the pop-up with the error.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error("Uncaught error:", error, errorInfo);
  }

  closePopup = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError) {
      // Render the pop-up if there's an error
      return (
        <React.Fragment>
          {this.props.children}
          <div className="error-popup">
            <h2>An error occurred</h2>
            <p>{this.state.error && this.state.error.toString()}</p>
            <button onClick={this.closePopup}>Close</button>
          </div>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
