import React from "react";
import { useLocation, Link } from "react-router-dom";
import "../style/NavBar.css";
import trackEvent from "../config/SimpleTracker";

const NavBar = () => {
  const handleClick = (clickName) => {
    trackEvent(clickName);
  };

  return (
    <div className="header">
      <Link to={"/"} onClick={() => handleClick("click_logo")}>
        <div className="GH__sigil">
          <p className="logo">gh.</p>
        </div>
      </Link>
      {/* <div className="navbar__links">
        <Link
          to={"/content/tiktok"}
          onClick={() => handleClick("click_tiktok")}
        >
          TikTok
        </Link>
        <Link to={"/content/reels"} onClick={() => handleClick("click_reels")}>
          IG Reels
        </Link>
        <Link
          to={"/content/shorts"}
          onClick={() => handleClick("click_shorts")}
        >
          YT Shorts
        </Link>
        <Link to={"/content/16:9"} onClick={() => handleClick("click_16_9")}>
          16:9
        </Link>
      </div> */}
    </div>
  );
};

export default NavBar;
