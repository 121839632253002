import React from "react";
import "../style/Landing.css";

export default function Privacy() {
  return (
    <div
      className="privacy__div"
      style={{ backgroundColor: "white", color: "black", padding: "20px" }}
    >
      <h2>Privacy Policy for Growhouse AB</h2>
      <p>Last updated: 2024-02-23</p>
      <p>
        Welcome to Growhouse AB, accessible via https://growhouse.co and
        https://grow.growhouse.co. Your privacy is critically important to us.
        This Privacy Policy document contains types of information that is
        collected and recorded by Growhouse AB and how we use it.
      </p>

      <h4>1. Definitions</h4>
      <p>
        <strong>Personal Data:</strong> Any information related to an identified
        or identifiable individual.
      </p>
      <p>
        <strong>Processing:</strong> Any operation or set of operations
        performed on personal data or on sets of personal data.
      </p>
      <p>
        <strong>Data Subject:</strong> Any living individual who is using our
        Service and is the subject of Personal Data.
      </p>

      <h4>2. Information Collection and Use</h4>
      <p>
        We collect several different types of information for various purposes
        to provide and improve our service to you. The types of data collected
        include:
      </p>
      <ul>
        <li>Personal Identification Information: Names, email addresses.</li>
        <li>
          Third-Party Data Integration: Meta, TikTok, Google Analytics, Stripe,
          Shopify, YouTube, LinkedIn, and X data from users' accounts into the
          Growhouse platform.
        </li>
        <li>
          Analytics: We use Simple Analytics to collect data such as pageviews,
          unique visitors, referrer data, browser information, operating system
          information, device type, and the country of the visitor. Simple
          Analytics is designed to ensure privacy and compliance with
          regulations like GDPR and CCPA.
        </li>
      </ul>

      <h4>3. Use of Data</h4>
      <p>Growhouse AB uses the collected data for various purposes:</p>
      <ul>
        <li>To provide and maintain our Service.</li>
        <li>To notify you about changes to our Service.</li>
        <li>
          To allow you to participate in interactive features of our Service
          when you choose to do so.
        </li>
        <li>To provide customer support.</li>
        <li>
          To gather analysis or valuable information so that we can improve our
          Service.
        </li>
        <li>To monitor the usage of our Service.</li>
        <li>To detect, prevent and address technical issues.</li>
        <li>
          For marketing purposes, specifically, your email address may be used
          to contact you with newsletters, marketing or promotional materials,
          and other information that may be of interest to you.
        </li>
      </ul>

      <h4>4. Sharing Your Personal Data</h4>
      <p>
        We may share your personal data with third parties in the following
        situations:
      </p>
      <ul>
        <li>
          HubSpot for Marketing Purposes: Your email address is shared with
          HubSpot for marketing purposes.
        </li>
        <li>
          Analytics: We use Simple Analytics for website data analysis as
          detailed above.
        </li>
      </ul>

      <h4>5. Consent and Withdrawal</h4>
      <p>
        Consent to collect personal data is obtained during the registration of
        an account with Growhouse and through third-party applications. You can
        withdraw your consent at any time by emailing us at email@growhouse.co.
      </p>

      <h4>6. Data Security</h4>
      <p>
        To protect your personal data, we have implemented several security
        measures, including:
      </p>
      <ul>
        <li>Data Minimization</li>
        <li>Access Controls</li>
        <li>Data Encryption in Transit</li>
        <li>Regular Security Assessments</li>
        <li>Secure Development Practices</li>
        <li>Incident Response Plan</li>
        <li>Data Retention and Disposal</li>
        <li>Training and Awareness</li>
        <li>Compliance with Regulations</li>
      </ul>

      <h4>7. Your Data Protection Rights</h4>
      <p>
        Growhouse AB aims to take reasonable steps to allow you to correct,
        amend, delete, or limit the use of your Personal Data. In accordance
        with data protection laws, you have rights that you can exercise
        regarding your data.
      </p>

      <h4>8. Changes to This Privacy Policy</h4>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>

      <h4>9. Contact Us</h4>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        by email at email@growhouse.co.
      </p>
    </div>
  );
}
