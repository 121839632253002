import React from "react";
import "../style/Landing.css";
import Hero from "../modules/Landing/Hero";
import Section1 from "../modules/Landing/Section1";
import Section2 from "../modules/Landing/Section2";
import Section3 from "../modules/Landing/Section3";
import SeedSection from "../modules/Landing/SeedSection";
import Section5 from "../modules/Landing/Section5";
import Section6 from "../modules/Landing/Section6";
import Section7 from "../modules/Landing/Section7";

function Landing() {
  return (
    <div className="landing">
      <Hero />
      <Section1 />
      <Section2 />
      <Section3 />
      {/* <SeedSection /> */}
      <Section5 />
      <Section6 />
      <Section7 />
    </div>
  );
}

export default Landing;
